'use client';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading, P } from '@mentimeter/ragnar-ui/typography';
import { Button } from '@mentimeter/ragnar-ui/button';
import Logo from '../Logo';

export default function NotFound() {
  return (
    <Box
      minHeight="100vh"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="stretch"
    >
      <Box
        flex="1"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Box
          flex="1"
          alignItems="center"
          justifyContent="center"
          py={4}
          px={3}
          bg="#E7E8EB"
        >
          <Logo />
          <Box
            width="90%"
            maxWidth={480}
            alignItems="center"
            borderRadius={4}
            bg="#FFF"
            p={32}
          >
            <Heading as="h1">This page does not exist</Heading>
            <P textAlign="center">
              Are you sure you typed it in correctly? If you followed a link to
              this address, please let us know!
            </P>
            <Box width="100%" alignItems="center">
              <Box flexDirection="row" p={3}>
                <Button size="large" variant="primary" href="/">
                  Go back home
                </Button>
                <Button
                  size="large"
                  variant="tertiary"
                  href="mailto:hello@mentimeter.com"
                  ml={3}
                >
                  Contact support
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
